import { Buttons, Wrapper } from "./EditSummaryPage.styled";

import { Editor } from "react-draft-wysiwyg";
// import { useLocation } from "react-router-dom";

import { Button } from "@/components";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const EditSummaryPage = () => {
  // const location = useLocation();
  // const id = location.pathname.split('/')[4];

  const handleDeleteSummary = () => {
    // openModal({
    //   active: 'RemoveVideo',
    //   data: {
    //     id: id!,
    //   },
    // })
  }

  return (
    <Wrapper>
      <Editor
        // editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        // onEditorStateChange={this.onEditorStateChange}
        editorStyle={{ border: '2px solid #E4E4FF', borderRadius: '10px', padding: '12px 16px 14px 16px', marginTop: '20px' }}
        toolbarStyle={{ background: '#140150', border: 'none', borderRadius: '10px', padding: '10px 20px ' }}
      />
      <Buttons>
        <Button theme="white">Сохранить</Button>
        <Button onClick={handleDeleteSummary}>Удалить</Button>
      </Buttons>
    </Wrapper>
  )
}
