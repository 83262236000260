import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query";


export const errorsRegistration = (errorData: FetchBaseQueryError, t: any) => {
    return Object.entries(errorData.data as { [key: string]: string[] }).map(([field, errors]) => {
        return errors.map((error: string) => {
            switch (field) {
                case 'email':
                    return t('reg_error_email', {ns: 'error'});
                case 'username':
                    return t('reg_error_username', {ns: 'error'});
                default:
                    return error.toString();
            }
        }).join('\n');
    });
};


