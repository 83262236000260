import styled from 'styled-components';

import { theme } from '@/styles';


export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 576px) {
        margin-top: 13vh;
    }

    @media (min-width: 992px) {
        flex-direction: row;
        gap: 50px;
        padding-left: 8vw;
        padding-right: 3vw;
    }

    @media (min-width: 1600px) {
        gap: 240px;
        padding-left: 14vw;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 768px) {
        padding-left: 40px;
    }

    @media (min-width: 992px) {
        gap: 40px;
        width: 495px;
    }
`;
export const WordGreen = styled.span`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 800;
    line-height: 170%;
    color: ${theme.colors.green.green_light};
    padding-right: 1px;
    padding-left: 1px;

    @media (min-width: 992px) {
        font-size: 21px;
        padding-right: 5px;
        padding-left: 5px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

export const Text = styled.p`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    color: ${theme.colors.label.label_light};

    @media (min-width: 992px) {
        font-size: 21px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;

    }
`;

export const ImageBack = styled.img`
    position: relative;
    width: 372px;
    height: 259px;
    top: 20%;
    left: -2%;
    right: -18%;
    margin-bottom: 42%;

    @media (min-width: 576px) {
        left: 35%;
    }

    @media (min-width: 768px) {
        margin-bottom: 30%;
    }

    @media (min-width: 992px) {
        left: -2%;
        top: 0;
    }

    @media (min-width: 1200px) {
        top: 20%;
        right: 10%;
        left: 0;
    }

    @media (min-width: 1400px) {
        position: absolute;
        right: 10%;
        margin-bottom: 0;
        margin-top: -45px;
        width: 550px;
        height: 352px;
        left: 58%;
    }
`;

export const ImageFront = styled.img`
    width: 372px;
    height: 259px;
    position: absolute;
    z-index: 4;
    top: 23%;
    right: 3%;

    @media (min-width: 576px) {
        right: 28%;
    }

    @media (min-width: 992px) {
        right: 9%
    }

    @media (min-width: 1200px) {
        position: absolute;
        z-index: 4;
        top: 30%;
        right: 20%;
    }

    @media (min-width: 1400px) {
        top: 30%;
        right: 17%;
        width: 550px;
        height: 352px;
    }
`;

export const ImageContainer = styled.div`

    @media (min-width: 576px) {
        margin-top: -18%;
    }

    @media (min-width: 768px) {
        margin-top: -10%;

    }

    @media (min-width: 992px) {
        margin-top: -89px;
    }

    @media (min-width: 1200px) {
        margin-top: -10%;
    }
`;
