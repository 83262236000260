export default {
  header: {
    search: 'Название плейлиста',
    addVideo: 'Добавить видео',
    createPlaylist: 'Создать плейлист',
    menu: {
      lib: 'Библиотека',
      stat: 'Статистика',
      set: 'Настройки аккаунта',
      about: 'О нас',
      admin: 'Личный кабинет',
      lang: 'Switch to English',
      logOut: 'Выйти',
      in: 'Войти',
      up: 'Регистрация',
      search: 'Поиск видео',
    },
  },
  pages: {
    statistics: {
      limit: 'Плейлистов на странице:',
      view: 'Просмотры',
      like: '«Мне нравится»',
      repost: 'Репосты',
      comment: 'Комментарии',
      save: 'Сохранения',
      sort: 'Сортировать',
      name: 'Названию',
    },
    account: {
      photo: 'Изменить',
      personal: 'Личный кабинет',
    },
    library: {
      no_pl: 'Здесь ещё нет плейлистов, но кнопка «Создать плейлист» в шапке страницы поможет добавить контент',
      no_v: 'Здесь ещё нет видео, но кнопка «Добавить видео» в шапке страницы поможет добавить контент',
    },
    main: {
      no_result: 'По вашему запросу нет результатов',
      no_pl: 'Пользователи еще не поделились плейлистами',
    },
    onboarding: {
      buttons: {
        nextGo: 'Далее',
        go: 'Бегу учиться!'
      },
      sl1: {
        sl1_text: 'Всё для быстрой и удобной',
        sl1_text2: 'учёбы по видео'
      },
      sl2: {
        sl2_h1: 'Добавляйте видео',
        sl2_text1: 'Мы обрабатываем видео',
        sl2_text2: 'с самых популярных платформ*.',
        sl2_span: '*Для организаций работаем с видео из любых источников.',
      },
      sl3: {
        sl3_h1: 'Ориентируйтесь',
        sl3_h2: 'в материале',
        sl3_word: 'таймкоды',
        sl3_text1: 'Мы сделаем',
        sl3_text2: 'с описаниями смысловых частей видео. Между ними',
        sl3_text3: 'можно будет переключаться.',
      },
      sl4: {
        sl4_h1: 'Усваивайте знания',
        sl4_text1: 'Мы напишем ',
        sl4_text2: 'с правильной структурой информации. То есть заметим отвлечения от темы и недочеты в порядке изложения —',
        sl4_text3: 'и всё исправим.',
        sl4_word: 'конспекты ',
      },
      sl5: {
        sl5_h1: 'Проверяйте себя',
        sl5_text1: 'Для этого у вас будут ',
        sl5_text2: 'Если ответите неправильно, предложим посмотреть фрагмент',
        sl5_text3: 'с ответом на вопрос.',
        sl5_word: 'тесты',
      },
      sl6: {
        sl6_h1: 'Ищите по контенту, как с Ctrl+F',
        sl6_word: 'поиска',
        sl6_text1: 'Есть возможность ',
        sl6_text2: ' по содержимому и конкретного ролика, и всего плейлиста.',
        sl6_text3: 'Напишите слово или фразу — мы поймём термины, дадим подсказки и найдём все упоминания.',
      },


      sl1_t: 'Ваша персональная подборка видео из любых сервисов',
      sl2_save: 'Сохраняйте ',
      sl2_save_d: 'все видео, которые вам нравятся, в одном месте',
      sl2_watch: 'Смотрите',
      sl2_watch_d: 'лучшие видео подборки других пользователей',
      sl2_share: 'Делитесь',
      sl2_share_d: 'целыми плейлистами со своими друзьями',
      sl3_t: 'В библиотеке отображаются все добавленные видео и ваши созданные плейлисты',
      sl4_t1: 'Составляйте плейлисты для себя или делитесь с широкой аудиторией и получайте признание и бонусы!',
      sl4_t2: 'Оценивайте, комментируйте и делитесь плейлистами в соцсетях',
      sl5_tg: 'Наш телеграм:',
      sl5_eml: 'Наша почта:',
      sl5_inst: 'Наш инстаграм:',
      innerSlider: {
        s0_n: 'Все для новичков в UX/UI',
        s0_d: 'Самая нужная база',
        s1_n: 'Трейлеры фильмов',
        s1_d: 'Когда надо быстро выбрать, что посмотреть',
        s2_n: 'Рецепты до 5 минут',
        s2_d: 'Супер-простые рецепты, когда время поджимает',
        s3_n: 'муд',
        s3_d: 'Картины и музеи',
        s4_n: 'Домашние растения',
        s4_d: 'Советы и уход',
        s5_n: 'Простые тренировки для дома',
        s5_d: 'Дома, как в зале',
      },
    },
    404: {
      ops: 'ОЙ!',
      info: 'кажется, что-то пошло не так...',
    },
    search: {
      title: 'глобальный поиск видео',
      input_placeholder: 'Введите название или опишите видео',
      search_button: 'Поиск',
      landscape: 'Горизонтальные',
      portrait: 'Вертикальные',
      count_title: 'Найдено {{countVideo}} видео',
      empty_input: 'Пожалуйста введите поисковый запрос',
      header: {
        pls: 'Плейлисты',
        saves: 'Сохраненные',
      },
      popup: {
        info: 'Оцените работу нашего сервиса',
        feedback_info:
          'Спасибо! Мы будем благодарны, если вы расскажете подробнее, это очень помогает нам улучшить сервис',
        placeholder: 'Напишите ваш комментарий здесь',
        send: 'Отправить',
        thanks: 'Спасибо за ваш отзыв!',
      },
      footer: {
        title: 'Поиск видео еще никогда не был таким удобным',
        target: 'Результаты даже по неточным запросам',
        target2: 'Одновременный поиск на крупнейших площадках',
        target3: 'Моментальный переход на нужный фрагмент',
      },
    },
    admin: {
      timecodes_title: 'Заголовок раздела',
      timecodes_text: 'Описание фрагмента',
      add_answer: 'Добавить ответ',
      title: 'Заголовок',
    },
  },
  components: {
    goBack: 'Назад',
    goHome: 'Вернуться на главную',
    goNext: 'Вперед',
    goSkip: 'Пропустить',
    goStart: 'Начать',
    pl_one: 'Плейлист',
    pl_other: 'Плейлисты',
    pl_video_count: 'видео',
    video_zero: 'Видео',
    video_one: 'Видео',
    video_two: 'Видео',
    video_few: 'Видео',
    video_many: 'Видео',
    video_other: 'Видео',
    search_in_video: 'Опишите нужный фрагмент видео',
    transcript: 'Транскрипт',
    timecodes: 'Таймкоды',
    quiz: 'Квиз',
    summary: 'Конспект',
    more: 'Развернуть описание',
    less: 'Скрыть описание',
    form: {
      name: 'Имя пользователя',
      email: 'Электронная почта',
      password: 'Пароль',
      cur_password: 'Текущий пароль',
      new_password: 'Новый пароль',
      repeat_password: 'Повторите пароль',
      forgot_pass: 'Забыли пароль?',
      cancel: 'Отменить',
      save: 'Сохранить',
      send: 'Отправить',
      del: 'Удалить',
      add: 'Добавить',
    },
    menu: {
      set_pl: 'Настройки плейлиста',
      del_pl: 'Удалить плейлист',
      del_vl: 'Удалить видео',
      timecodes: 'Таймкоды',
      summary: 'Конспект',
      quiz: 'Тест',
    },
    breadcrumbsItems: {
      toHome: 'Главная',
      toLib: 'Библиотека',
      toPl: 'Плейлист',
      toVideo: 'Видео',
    },
  },
  modal: {
    reg: 'Зарегистрироваться',
    reg_no_acc: 'Уже есть аккаунт? ',
    reg_info: ' Нажимая “Зарегистрироваться”, вы принимаете',
    log_no_acc: 'Еще нет аккаунта?',
    add_v_to_pl: 'Добавить видео в плейлист',
    add_v_to_pl_insert: 'Добавьте в плейлист видео из своей библиотеки',
    add_v_to_pl_no_v: 'В библиотеке еще нет видео',
    video_insert: 'Вставьте ссылку на понравившееся видео',
    video_insert_add_v: 'или вставьте ссылку на понравившееся видео',
    video_choose: 'или выберите видео из одного из источников',
    video_select_pl: 'Выберите плейлист, в который видео сохранится',
    video_no_select: 'не выбран',
    category: 'Категория',
    pl_name: 'Название плейлиста',
    pl_name_ph: 'Рецепты из авокадо',
    descr: 'Описание плейлиста',
    descr_ph: 'Ура, смогу хвастаться вкусными блюдами',
    priv_set: 'Настройки приватности',
    del_pl: 'Вы уверены, что хотите удалить плейлист?',
    del_acc: 'Вы уверены, что хотите удалить аккаунт?',
    del_v: 'Вы уверены, что хотите удалить видео?',
    res_pass: 'Восстановление пароля',
    res_email: 'Активация почты',
    verify_email: 'Подтверждение почты',
    verify_email_err: 'Ошибка, аккаунт не верифицирован',
    verify_email_suc: 'Аккаунт успешно верифицирован',
    verify_email_info: 'Вам на почту {{email}} отправлено письмо для подтверждения Ваших учетных данных.',
    reset_password_info: 'Вам на почту {{email}} отправлено письмо для восстановления пароля.',
    change_password: 'Смена пароля',
    suggest_video: 'Добавить видео в плейлист с помощью ИИ',
    suggest: 'Выбор видео',
  },
  notification: {
    welcome: 'Добро пожаловать!',
  },
  options: {
    psychology: 'Психология',
    travel: 'Путешествия',
    work: 'Работа',
    languages: 'Языки',
    education: 'Образование',
    humor: 'Юмор',
    useful: 'Полезное',
    recipes: 'Рецепты',
    children: 'Дети',
    flowers: 'Цветы',
    hobby: 'Хобби',
    sport: 'Спорт',
    music: 'Музыка',
    cinema: 'Кино',
    public: 'Публичный',
    By_link: 'По ссылке',
    private: 'Личный',
  },
  error: {
    error: 'Ошибка, попробуйте позже',
    auth: 'Ошибка авторизации',
    refresh: 'Время сессии истекло',
    500: 'Сервер не отвечает',
    404: 'Результат запроса не найден',
    err_timecodes: 'К сожалению, произошла ошибка. Пожалуйста, обратитесь за помощью',
    server: 'Ошибка сервера',
    fetch: 'Ошибка соединения',
    req_field: 'Поле обязательно для заполнения',
    field_uniq: 'Название плейлиста должно быть уникальным.',
    reg: 'Не удалось зарегистрироваться',
    reg_error_email: 'Пользователь с таким адресом электронной почты уже существует.',
    reg_error_username: 'Пользователь с таким именем уже существует.',
    acc_inactive: 'Аккаунт пользователя неактивен',
    log: 'Не удалось войти',
    req_field_login: 'Аккаунт с указанными учетными данными не найден',
    set_pass: 'Не удалось сменить пароль',
    set_pass_reset_lint: 'Данные из сслыки больше не валидны',
    incor_cred: 'Неверный логин или пароль',
    incor_email: 'Некорректный адресс электронной почты',
    incor_password: 'Пароль должен содержать буквы и цифры',
    inverify_email: '{{email}} не верифицирован',
    min_char: 'Минимум {{length}} символов',
    max_char: 'Максимум {{length}} символов',
    incor_name: 'Некорректное название',
    no_save_ch: 'Изменения не сохранены',
    try_l: 'Попробуйте позже',
    size: 'Максимальный размер файла до {{size}}Мб',
    w_video: 'Для просмотра видео авторизуйтесь',
    add_v_not_sel: 'Вы не выбрали видео',
    add_v_empty_link: 'Пожалуйста, вставьте ссылку на видео',
    add_v_uncor_link: 'Сервер не может обработать ссылку, проверьте ссылку',
    del_pl: 'Плейлист не удален',
    del_acc: 'Аккаунт не удален',
    del_v: 'Видео не удалено',
    del_timecodes: 'Не удалось удалить таймкоды',
    save_timecodes: 'Не удалось сохранить таймкоды',
    password_no_match: 'Пароль не совпадает',
    change_video_title: 'Название не может быть пустым',
    link: 'Ошибка получения ссылки',
    resend: 'Отправить повторно',
  },
  loading: {
    load_timecodes: 'Таймкоды уже формируются!',
    load_quiz: 'Тест уже формируется!',
  },
  success: {
    cr_pl: 'Плейлист {{name}} успешно создан!',
    ed_pl_name: 'Плейлист {{name}} успешно изменен на {{editName}}!',
    ed_pl: 'Плейлист {{editName}} успешно изменен!',
    logout: 'Вы успешно вышли из аккаунта!',
    registration: 'Ваш аккаунт успешно создан!',
    link: 'Ссылка скопирована!',
    data: 'Данные успешно изменены',
    format: 'Разрешенный формат файла {{format}}',
    add_video: 'Видео успешно добавлено в раздел "Видео"',
    add_video_pl: 'Видео успешно добавлено в плейлист',
    add_videos_pl_n: 'Видео добавлено(ы) в плейлист {{name}}',
    del_pl: 'Плейлист успешно удален',
    del_acc: 'Аккаунт успешно удален',
    del_v: 'Видео успешно удалено',
    del_timecodes: 'Таймкоды успешно удалены!',
    save_timecodes: 'Таймкоды успешно сохранены!',
    verify_email: 'Вам на почту отправлено письмо для подтверждения Ваших учетных данных',
    set_password: 'Пароль успешно изменен, войдите в аккаунт',
    change_video_title: 'Название успешно изменено',
  },
};
