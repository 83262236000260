import styled from 'styled-components';

export const TimecodesFormWrapper = styled.form`
width: 100%;
display: flex;
flex-direction: column;
gap: 16px;
justify-content: center;
align-items: flex-start;
`;
