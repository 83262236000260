import styled from 'styled-components';

import {theme} from "@/styles";

export const ContentWrapper = styled.div`
    display: flex;
    gap: 82px;
    position: relative;
    top: 20%;

    @media (min-width: 576px) {
        margin-top: 13vh;
        padding-left: 18vw;
        padding-right: 18vw;
        display: flex;
        gap: 82px;
        top: 0;
    }

    @media (min-width: 992px) {
        padding-left: 5vw;
        padding-right: 46vw;
    }

    @media (min-width: 1200px) {
        padding-left: 7vw;
        padding-right: 38vw;
    }

    @media (min-width: 1400px) {
        padding-left: 18vw;
    }
`;

export const TextFrame = styled.div`
    width: 464px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (min-width: 992px) {
        max-width: 100%;
    }

    @media (min-width: 1200px) {
        margin-bottom: 40px;
    }
`;

export const HeaderImage = styled.img`
    max-width: 330px;
    margin: 0 auto;

    @media (min-width: 576px) {
        max-width: 400px;
        margin: 0;
    }

    @media (min-width: 992px) {
        width: auto;
    }
`;

export const TextContent = styled.p`
    font-family: ${theme.fonts.nunito};
    font-size: 26px;
    font-weight: 400;
    line-height: 170%;
    color: ${theme.colors.label.label_light};
    margin: 0 auto;

    @media (min-width: 576px) {
        margin: 0;
    }

    @media (min-width: 992px) {
        font-size: 26px;
    }
`;

export const TabletImageWrapper = styled.div`
    width: 30vw;

    @media (min-height: 1300px) {
        width: 65vw;
        margin-top: 20px;
        margin-left: auto;
        margin-right: 0;
    }
`;
export const TabletImage = styled.img`
    display: none;

    @media (min-width: 992px) {
        display: block;
        width: 45%;
        position: absolute;
        top: -33%;
    }

    @media (min-width: 1200px) {
        width: 52%;
        padding-right: 10vh;
    }

    @media (min-width: 1600px) {
        top: -40%;
        width: 939px;
        padding-right: 0;
        
    }
`;

export const PositionImg = styled.img`
    display: none;
    
    @media (min-width: 992px) {
        display: block;
        width: 25%;
        position: absolute;
        top: 27%;
        left: 45%;
    }

    @media (min-width: 1200px) {
        top: 31%;
        left: 39%;
        width: 30%;
    }

    @media (min-width: 1400px) {
        top: 43%;
        left: 48%;
    }
    
    @media (min-width: 1600px) {
        width: 440px;
        top: 70%;
        left: 39%;
    }
`;