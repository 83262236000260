import styled from 'styled-components';

import { theme } from '@/styles';


export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 576px) {
        margin-top: 13vh;
    }

    @media (min-width: 992px) {
        flex-direction: row;
        padding-left: 8vw;
        padding-right: 3vw;
    }

    @media (min-width: 1200px) {
        gap: 50px;
    }

    @media (min-width: 1400px) {
        gap: 0;
    }
    
    @media (min-width: 1700px) {
        padding-left: 18vw;
        padding-right: 0;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: 576px) {
        padding-left: 40px;
        width: 450px;
        gap: 9px;
    }

    @media (min-width: 992px) {
        width: 494px;
        gap: 40px;
        padding-left: 0;
    }

    @media (min-width: 1200px) {
        width: 536px;
    }
`;
export const WordGreen = styled.span`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 800;
    line-height: 170%;
    color: ${theme.colors.green.green_light};
    padding-right: 1px;
    padding-left: 1px;

    @media (min-width: 992px) {
        font-size: 21px;
        padding-right: 5px;
        padding-left: 5px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

export const TextContinue = styled.span`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    color: ${theme.colors.label.label_light};

    @media (min-width: 992px) {
        font-size: 21px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;
export const TextBlockWrap = styled.div`
    width: 460px;
`;

export const Text3Block = styled.p`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    color: ${theme.colors.label.label_light};

    @media (min-width: 992px) {
        font-size: 21px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

export const Image = styled.img`
    position: relative;
    right: -9%;
    margin-bottom: 5px;
    width: 610px;
    height: 375px;

    @media (min-width: 576px) {
        margin-top: -10%;
    }

    @media (min-width: 992px) {
        margin-bottom: 0;
        margin-top: -49px;
    }

    @media (min-width: 1200px) {
        top: 15%;
        right: -9%;
    }

    @media (min-width: 1600px) {
        width: 960px;
        height: 636px;
    }
`;