// import Slider from "react-slick";
// import { useAppDispatch, useTypedSelector } from "../../hooks/useStore";
// import { fetchPlaylistsUnLogged } from "../../store/slices/playlistsSlice";

import { Inner, Wrapper, SearchInput, TitleWithButtonWrapper } from './AdminPage.styled';

import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';
import { useHandlingError } from '../../hooks/useErrorHandling';
import { CardItem, CardList } from '../LibraryPage';

import { memo, useEffect } from 'react';

import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { playlistsAPI } from '@/api';
import { UserPlaylistCard } from '@/components/Card';
import { Button } from '@/components/Shared/Button';
import { useAppSelector, useMatchMedia, usePagination } from '@/hooks';
import { PaginationWrapper, TitleH3 } from '@/styles';


const MainPage = () => {
  // const [, { search, statusSearch }] = useAppSelector((state) => [state.user.user_id, state.search]);

  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();
  const { isAuth: auth, username, avatar } = useAppSelector((state) => state.user);
  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const isDesktop = useMatchMedia('(min-width: 1025px)');

  const {
    data: playlists,
    isFetching,
    isLoading,
    isSuccess,
    error
  } = playlistsAPI.useGetMyPlaylistsQuery({}, { skip: !auth });

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  useEffect(() => {
    setCount(playlists?.count);
  }, [playlists]);

  return (
    <Wrapper>
      <TitleWithButtonWrapper>
        <TitleH3>{t('account.personal')}</TitleH3>
        <Button theme='white'>Редактировать квиз</Button>
      </TitleWithButtonWrapper>
      <SearchInput placeholder={t('search.input_placeholder')} />

      <Inner>
        {(isLoading || isFetching) && <FullScreenLoader />}
        {isSuccess && (
          <>
            <CardList>
              {playlists?.results.map((item, i) => (
                <CardItem key={i}>
                  <UserPlaylistCard playlist={item} name={username.slice(0, 10)} avatar={avatar} isAdmin />
                </CardItem>
              ))}
            </CardList>
            {playlists?.results.length > 0 && (
              <PaginationWrapper>
                {pages != 1 && (
                  <Pagination
                    count={pages}
                    page={page}
                    size={isDesktop ? 'medium' : 'small'}
                    color="primary"
                    onChange={handleChangePage}
                  />
                )}
              </PaginationWrapper>
            )}
          </>
        )}
      </Inner>
    </Wrapper>
  );
};

export default memo(MainPage);
