import { Buttons, Wrapper } from "./EditTranscriptPage.styled";

import { SetStateAction, useState } from "react";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

// import { useGetTransciptsAdminQuery } from "@/api";
import { Button } from "@/components";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


export const EditTranscriptPage = () => {
  const editorState = EditorState.createEmpty()
  const [transcript, setTranscript] = useState(editorState)
  // const id = location.pathname.split('/')[4];

  // const { data: transcripts, isLoading } = useGetTransciptsAdminQuery({ videoPk: id })

  const onEditorStateChange = (newState: SetStateAction<EditorState>) => {
    setTranscript(newState)
  }

  return (
    <Wrapper>
      <Editor
        editorState={transcript}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        editorStyle={{ border: '2px solid #E4E4FF', borderRadius: '10px', padding: '12px 16px 14px 16px', marginTop: '20px' }}
        toolbarStyle={{ background: '#140150', border: 'none', borderRadius: '10px', padding: '10px 20px ' }}
      />
      {/* <textarea name="" id="" style={{display: none}}></textarea> */}
      <Buttons>
        <Button theme="white">Сохранить</Button>
        <Button>Удалить</Button>
      </Buttons>
    </Wrapper>
  )
}
