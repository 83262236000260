import { Outlet } from 'react-router-dom';

import {BreadCrumbs} from "@/pages/PlayListOutlet/components";


const PlayListOutlet = () => {
  return (
    <>
      <BreadCrumbs />
      {/* <VideosArrows /> */}
      <Outlet />
    </>
  );
};

export default PlayListOutlet;
