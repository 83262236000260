import { TimecodesForm } from "./components/TimecodesForm";
// import { TimecodesRenderComponent } from "./components/TimecodesRenderComponent/TimecodesRenderComponent";
// import { TitleTime } from "./components/TitleAndTime";
import { Wrapper } from "./EditTimecodesPage.styled";

import { useMemo, useRef, useState } from "react";

// import { FormProvider, useFieldArray, useForm } from "react-hook-form";
// import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
// eslint-disable-next-line import/named
import YouTube, { YouTubeEvent, YouTubeProps } from "react-youtube";

import { useGetTimecodesAdminQuery, videosAPI } from "@/api";
import { FullScreenLoader } from "@/components";
// import PlusBigIconBlack from "@/components/SvgIcons/PlusBigIconBlack";
import { useAppSelector } from "@/hooks";
import { VideoWrapper } from "@/pages/VideoPage/VideoPage.styled";
// import { Timecode } from "@/types";

////TODO: добавить возможность удалять некоторые таймкоды по индексу

export const EditTimecodesPage = () => {

  const location = useLocation();
  const id = location.pathname.split('/')[4];
  // const playlistId = location.pathname.split('/')[2];

  const iframeWrapper = useRef<HTMLDivElement>(null);
  const iframe = useRef<YouTube>(null);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [params] = useSearchParams();
  const [_, setCurrentTime] = useState(null)

  const { data: timecodes, isLoading } = useGetTimecodesAdminQuery({ videoPk: id })
  const {
    data: video,
  } = videosAPI.useGetMovieByIdQuery({ id: id ?? '' }, { skip: !isAuth });


  const goToTimeFunc = async (event: YouTubeEvent) => {
    await event.target.seekTo(params.get('t') ?? 0, true);
    await event.target.playVideo();
  };

  const getCurrentTimeFunc = async () => {
    setCurrentTime((await iframe.current?.internalPlayer.getCurrentTime()) || 0);
  };

  let timerId: number;
  const onStateChange: YouTubeProps['onStateChange'] = (event) => {
    if (event.data === 1) {
      timerId = setInterval(() => {
        getCurrentTimeFunc();
      }, 1000);
    } else if (event.data === 2) {
      clearInterval(timerId)
    }
  };

  const startsForm = useMemo(() => {
    const time = params.get('t');
    return time ? parseInt(time) : 0;
  }, [params]);




  // const handleSaveTimecodes = async () => {
  //   const result = await updateTimecodes({ videoPk: id, publicId: playlistId, body: newTimecodes })
  //   console.log(result)

  //   if ('error' in result) {
  //     showNotification({
  //       text: t('save_timecodes', { ns: 'error' }),
  //       severity: 'error',
  //     });
  //   } else {
  //     showNotification({
  //       text: t('save_timecodes', { ns: 'success' }),
  //       severity: 'success',
  //     });
  //   }
  // }






  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}

      {video &&
        (<VideoWrapper ref={iframeWrapper}>
          {video.source === 'YOUTUBE' && (
            <YouTube
              videoId={video.videoId}
              title={video.title}
              ref={iframe}
              style={{ width: '100%', height: '308px' }}
              onStateChange={onStateChange}
              onReady={goToTimeFunc}
              opts={{
                height: '308px',
                width: '100%',
                playerVars: {
                  start: video.startsFrom || startsForm,
                  autoplay: 1,
                  rel: 0,
                },
              }}
            />
          )}

          {video.source === 'VK' && (
            <iframe
              title={video.title}
              src={`${video.originLink}&hd=2&autoplay=14&t=${video.startsFrom || startsForm}s`}
              width="100%"
              height="500px"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
            ></iframe>
          )}
        </VideoWrapper>)
      }
      <TimecodesForm timecodes={timecodes?.timecodes || []} publicId={timecodes?.publicId || ''} />
    </Wrapper >
  )
}
