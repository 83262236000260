import { useLatest } from './useLatest';
import { useMethods } from './useMethods';

import { AccountStat } from '../types/contentTypes';

import { useLayoutEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

type PLKey = Exclude<keyof AccountStat, 'background_image' | 'link' | 'description'>;
type Order = 'asc' | 'desc';
type State = {
  sort: Order | null;
  accessor: PLKey;
  data: AccountStat[];
};

export function useSortStatistics(data: AccountStat[]) {
  const [params, setParams] = useSearchParams();

  const sortParamFunc = useLatest(() => {
    const sort = params.get('sortSt');
    return sort ? (sort as Order) : null;
  });

  const accessorParamFunc = useLatest(() => {
    const accessor = params.get('accessor');
    return accessor ? (accessor as PLKey) : 'id';
  });

  const [initialState] = useState<State>({
    sort: sortParamFunc.current(),
    accessor: accessorParamFunc.current(),
    data,
  });

  const resetState = useLatest<State>({
    sort: null,
    accessor: 'id',
    data,
  });

  const [state, action] = useMethods({
    initialState,
    methods: {
      makeASC(state, payload: PLKey) {
        state.data = state.data.sort((a, b) => {
          if (payload !== 'name') {
            return b[payload] - a[payload];
          } else {
            return a.name.toLowerCase() > b.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() < b.name.toLowerCase()
                ? 1
                : 0;
          }
        });
        state.accessor = payload;
        state.sort = 'asc';
      },
      makeDESC(state, payload: PLKey) {
        (state.data = state.data.sort((a, b) => {
          if (payload !== 'name') {
            return a[payload] - b[payload];
          } else {
            return a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0;
          }
        })),
          (state.accessor = payload);
        state.sort = 'desc';
      },
      reset(state) {
        state.data = resetState.current.data;
        state.accessor = resetState.current.accessor;
        state.sort = resetState.current.sort;
      },
      update(state, payload: AccountStat[]) {
        state.data = payload;
      },
    },
  });

  useLayoutEffect(() => {
    action.update(data);
  }, [data]);

  const setSortHandler = (key: PLKey) => {
    const actualParams = Object.fromEntries([...params]);
    if (key === state.accessor) {
      switch (state.sort) {
        case 'asc':
          action.makeDESC(key);
          setParams(Object.assign(actualParams, { sortSt: 'desc', accessor: key }));
          break;
        case 'desc':
          action.reset();
          delete actualParams.sortSt;
          delete actualParams.accessor;
          setParams(actualParams);
          break;
        default:
          action.makeASC(key);
          setParams(Object.assign(actualParams, { sortSt: 'asc', accessor: key }));
      }
    } else {
      action.makeASC(key);
      setParams(Object.assign(actualParams, { sortSt: 'asc', accessor: key }));
    }
  };

  return { state, setSortHandler };
}
