import { AvatarMenuArrow, AvatarWrapper } from './AvatarWithMenu.styled';

import Avatar from '../../Shared/Avatar';
import DropDown from '../../Shared/DropDown/DropDown';
import DropDownMenuItem from '../../Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import ArrowSmall from '../../SvgIcons/ArrowSmall';
import ExitIcon from '../../SvgIcons/ExitIcon';
import GuestIcon from '../../SvgIcons/GuestIcon';
import InfoIcon from '../../SvgIcons/InfoIcon';
import LanguageIcon from '../../SvgIcons/LanguageIcon';
import LibraryIcon from '../../SvgIcons/LibraryIcon';
import SearchIcon from '../../SvgIcons/SearchIcon';
import SettingsIcon from '../../SvgIcons/SettingsIcon';
import SignInIcon from '../../SvgIcons/SignInIcon';
import SignUpIcon from '../../SvgIcons/SignUpIcon';
// import StatisticsIcon from '../../SvgIcons/StatisticsIcon';

import { Reducer, memo, useMemo, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { authAPI, usersAPI } from '@/api';
import { REFRESH_TOKEN } from '@/constants';
import { useActions, useAppSearchParams, useAppSelector } from '@/hooks';

const AvatarWithMenu = () => {
  const { t, i18n } = useTranslation('header');
  const { setFinishedOnboarding, logout, showNotification } = useActions();
  const [fetchLogout] = authAPI.useLogOutUserMutation();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { setAppParams } = useAppSearchParams();
  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const toggleHandler = () => {
    setOpened(opened);
  };

  const { isAuth: auth, username, isCommercial } = useAppSelector((state) => state.user);

  const { data } = usersAPI.useGetUserMeQuery(undefined, { skip: !auth });

  const changeLanguage = () => {
    switch (i18n.language) {
      case 'ru':
        i18n.changeLanguage('en');
        break;
      case 'en':
        i18n.changeLanguage('ru');
        break;
      default:
        i18n.changeLanguage('en');
    }
  };
  const exit = () => {
    fetchLogout({ refresh: localStorage.getItem(REFRESH_TOKEN) as string });
    logout();

    if (pathname.includes('private')) {
      navigate('/', { replace: true });
    }

    showNotification({
      text: t('logout', { ns: 'success' }),
      severity: 'success',
    });
  };

  const items = useMemo(() => {
    if (auth && !isCommercial) {
      return [
        {
          text: t('menu.lib'),
          icon: <LibraryIcon />,
          cb: () => navigate('/library'),
        },
        {
          text: t('menu.set'),
          icon: <SettingsIcon />,
          cb: () => navigate('/profile'),
        },
        {
          text: t('menu.search'),
          icon: <SearchIcon />,
          cb: () => navigate('/search'),
        },
        {
          text: t('menu.lang'),
          icon: <LanguageIcon />,
          cb: changeLanguage,
        },
        {
          text: t('menu.about'),
          icon: <InfoIcon />,
          cb: setFinishedOnboarding,
        },
        {
          text: t('menu.logOut'),
          icon: <ExitIcon />,
          cb: exit,
        },
        // <DropDownMenuItem  key="Уведомления" icon={<BellIcon />} text='Уведомления' />,
      ];
    } else if (auth && isCommercial) {
      return [
        {
          text: t('menu.lib'),
          icon: <LibraryIcon />,
          cb: () => navigate('/library'),
        },
        {
          text: t('menu.admin'),
          icon: <GuestIcon />,
          cb: () => navigate('/account'),
        },
        {
          text: t('menu.set'),
          icon: <SettingsIcon />,
          cb: () => navigate('/profile'),
        },
        {
          text: t('menu.admin'),
          icon: <GuestIcon />,
          cb: () => navigate('/account'),
        },
        {
          text: t('menu.search'),
          icon: <SearchIcon />,
          cb: () => navigate('/search'),
        },
        {
          text: t('menu.lang'),
          icon: <LanguageIcon />,
          cb: changeLanguage,
        },
        {
          text: t('menu.about'),
          icon: <InfoIcon />,
          cb: setFinishedOnboarding,
        },
        {
          text: t('menu.logOut'),
          icon: <ExitIcon />,
          cb: exit,
        },
      ];
    }

    return [
      {
        text: t('menu.in'),
        icon: <SignInIcon />,
        cb: () => setAppParams('popup', 'login'),
      },
      {
        text: t('menu.lang'),
        icon: <LanguageIcon />,
        cb: changeLanguage,
      },
      {
        text: t('menu.up'),
        icon: <SignUpIcon />,
        cb: () => setAppParams('popup', 'registration'),
      },
    ];
  }, [auth, i18n, navigate, setAppParams, setFinishedOnboarding, t]);

  return (
    <>
      <DropDown
        isOpen={opened}
        toggleOpen={toggleHandler}
        items={items.map((item) => (
          <DropDownMenuItem key={item.text} icon={item.icon} text={item.text} onClick={item.cb} />
        ))}
      >
        <AvatarWrapper open={opened}>
          <Avatar auth={auth} size="s" url={data?.avatar} name={username} />
          <AvatarMenuArrow>
            <ArrowSmall className="arrow" />
          </AvatarMenuArrow>
        </AvatarWrapper>
      </DropDown>
    </>
  );
};

export default memo(AvatarWithMenu);
