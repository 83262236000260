import styled from 'styled-components';

import { theme } from '@/styles';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 576px) {
        margin-top: 13vh;
    }

    @media (min-width: 992px) {
        flex-direction: row;
        gap: 50px;
        padding-left: 8vw;
        padding-right: 3vw;
    }

    @media (min-width: 1600px) {
        gap: 240px;
        padding-left: 18vw;
    }
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;

    @media (min-width: 576px) {
        padding-left: 40px;
        width: 450px;
    }

    @media (min-width: 992px) {
        width: 494px;
        gap: 40px;
    }

    @media (min-width: 1200px) {
        width: 536px;
    }
`;

export const TextHeader = styled.h1`
    font-family: ${theme.fonts.nunito};
    font-size: 26px;
    font-weight: 700;
    line-height: 130%;
    color: ${theme.colors.white.white_100};

    @media (min-width: 992px) {
        font-size: 44px;
    }

    @media (min-width: 1400px) {
        font-size: 52px;
    }
`;

export const Text1 = styled.span`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    color: ${theme.colors.label.label_light};

    @media (min-width: 992px) {
        font-size: 21px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

export const Text3 = styled.p`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    color: ${theme.colors.label.label_light};

    @media (min-width: 992px) {
        font-size: 21px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;
export const Step4Image = styled.img`
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 410px;
    margin-bottom: 15px;


    @media (min-width: 576px) {
        margin-top: -18%;
    }

    @media (min-width: 768px) {
        margin-top: -10%;
    }

    @media (min-width: 992px) {
        margin-bottom: 0;
        margin-top: -89px;
        width: 422px;
        height: 586px;
    }

    @media (min-width: 1600px) {
        width: 481px;
        height: 628px;
    }
`;
export const TextGreen = styled.span`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 800;
    line-height: 170%;
    color: ${theme.colors.green.green_light};
    padding-right: 1px;
    padding-left: 1px;

    @media (min-width: 992px) {
        font-size: 21px;
        padding-right: 5px;
        padding-left: 5px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;
