import { QuizAnswersWrapper, QuizBlockWrapper } from "./QuizBlock.styled"

import { QuizVariant } from "../QuizVariant"

import { useTranslation } from "react-i18next"

import { Button } from "@/components/Shared/Button"
import MyInput from "@/components/Shared/MyInput/MyInput"
import { useAppDispatch } from "@/hooks"
import { addAnswer, addQuestion } from "@/store/slices/quizAdminSlice"
import { QuizAnswersType } from "@/types/adminTypes"


export const QuizBlock = ({ question, answers = [], id }: any) => {
  const { t } = useTranslation('pages');
  const dispatch = useAppDispatch();

  const handleAddQuestion = (e: { target: { value: string } }) => {
    dispatch(addQuestion({ questionIndex: id, newQuestion: e.target.value }));
  };

  const handleAddAnswer = () => {
    dispatch(addAnswer({ questionIndex: id, answer: '' }));
  };

  return (
    <QuizBlockWrapper>
      <><MyInput label={t('admin.title')} placeholder="" value={question} onChange={handleAddQuestion} /></>
      <>
        <QuizAnswersWrapper>
          {answers.map((answer: QuizAnswersType, index: number) => (
            <QuizVariant key={index} {...answer} questionIndex={id} id={index} />
          ))}
        </QuizAnswersWrapper>
        <Button theme="white" style={{ padding: '12px 30px', maxWidth: '200px' }} onClick={handleAddAnswer}>{t('admin.add_answer')}</Button>
      </>
    </QuizBlockWrapper>
  )
}
