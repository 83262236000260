import { QuizBlock } from "./components/QuizBlock"
import { QuizPageWrapper } from "./EditQuizPage.styled"

import { Buttons } from "../EditTimecodesPage/EditTimecodesPage.styled"

import { useLocation } from "react-router-dom"

import { useGetQuizzesAdminQuery } from "@/api"
import { Button } from "@/components/Shared/Button"
import PlusBigIconBlack from "@/components/SvgIcons/PlusBigIconBlack"
import { useAppDispatch, useAppSelector } from "@/hooks"
import { addBlock, addTheWholeQuiz } from "@/store/slices/quizAdminSlice"
import { RootState } from "@/store/store"

export const EditQuizPage = () => {
  const location = useLocation();
  const playlistId = location.pathname.split('/')[2];
  const dispatch = useAppDispatch();

  const { data: quizes } = useGetQuizzesAdminQuery({ playlistPk: playlistId })
  if (quizes?.results.length > 0) {
    dispatch(addTheWholeQuiz(quizes.results))
  }

  const questions = useAppSelector((state: RootState) => state.quizAdmin.questions);

  const handleAddQuestionBlock = () => {
    dispatch(addBlock());
  }

  const handleSubmitQuiz = () => {
    //TODO: сделать, когда появится эндпоинт
  }

  const handleDeleteQuiz = () => {
    //TODO: Сделать, когда появится эндпоинт
  }

  return (
    <QuizPageWrapper>
      {questions.map((question, index) => (
        <QuizBlock key={index} {...question} id={index} />
      ))}
      <Button theme="white" style={{ padding: '13px', maxWidth: '50px' }} onClick={handleAddQuestionBlock}><PlusBigIconBlack /></Button>

      <Buttons>
        <Button theme="white" onClick={handleSubmitQuiz}>Сохранить</Button>
        <Button onClick={handleDeleteQuiz}>Удалить</Button>
      </Buttons>
    </QuizPageWrapper>
  )
}
