import { CardList } from '../LibraryPage';
import { VideoList } from '../PlayListOutlet/components/VideoList/VideoList';
import {
  Title,
  Text,
  Description,
  DescriptionContent,
  DescriptionImage,
  StatisticsList,
  StatisticsPanel,
  StatisticsPanelItem,
  StatisticsPanelItemText,
  TextWrapper,
  VideoListWrapper,
  DescriptionImageWrapper,
  StatusInfo,
} from '../PlayListPage';
import { getStatisticsItems } from '../PlayListPage/helpers/getStatisticsItems';

import { memo, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';

import {playlistsAPI} from '@/api';
import { FullScreenLoader } from '@/components';
import { VideoFragmentCard } from '@/components/Card';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import {LockIcon} from "@/components/SvgIcons";
import { useAppSelector, useHandlingError } from '@/hooks';
import { useActions } from '@/hooks/useActions';

const PlaylistPrivatePage = () => {
  const { t } = useTranslation('components');

  const { showNotification } = useActions();
  const { catchError } = useHandlingError();

  const navigate = useNavigate();

  const [isTextCollapsed, setIsTextCollapsed] = useState(true);
  const { hash } = useParams();
  const [isAuth, user_id] = useAppSelector((state) => [state.user.isAuth, state.user.user_id]);

  const [params, setParams] = useSearchParams();

  const searchQueryValue = params.get('search');

  const {
    data: playlist,
    isLoading,
    error,
  } = playlistsAPI.useReadPrivateLinkQuery({ linkHash: hash || '' }, { skip: !hash || !isAuth });

  const [getSearchVideos, { data: searchVideos, isLoading: isSearchLoading, error: searchError }] =
    playlistsAPI.useLazyGetFullSearchQuery();

  // TODO: раскомемнтировать для проверки запроса
  // const { data: allQuizzes } = useGetAllQuizzesQuery({ playlistId: playlistId ?? '' }, { skip: !playlistId });

  useEffect(() => {
    catchError(error || searchError);
  }, [error, searchError]);

  useEffect(() => {
    if (!isAuth) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  if (playlist && playlist.owner.publicId === user_id) {
    navigate(`/playlist/${playlist.publicId}`, { replace: true });
  }

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchVideos({ query, publicId: playlist?.publicId || '' });
    },
    [playlist],
  );

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {playlist && (
        <>
          <Description>
            <DescriptionImageWrapper>
              <DescriptionImage src={playlist.category.image ?? '/images/playlist_mock.png'} alt={playlist.title} />
              <StatusInfo>
                {playlist.privacyType === 'private' && <LockIcon theme="outline" />}

                {/* <UnlockIcon theme="outline" /> */}
              </StatusInfo>
            </DescriptionImageWrapper>
            <DescriptionContent>
              <Title>{playlist.title}</Title>
              <TextWrapper isCollapsed={isTextCollapsed}>
                <Text>
                  {isTextCollapsed ? playlist.description.slice(0, 127) : playlist.description}
                  {isTextCollapsed && playlist.description.length > 127 ? '... ' : ' '}
                  {playlist.description.length > 100 && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <span
                      onClick={() => setIsTextCollapsed(!isTextCollapsed)}
                      style={{ cursor: 'pointer', opacity: 0.6, fontSize: 12 }}
                    >
                      {isTextCollapsed ? t('more') : t('less')}
                    </span>
                  )}
                </Text>
              </TextWrapper>
            </DescriptionContent>
          </Description>
          {isAuth && <SearchInVideoInput getSearch={getSearchVideosHandler} />}
          <StatisticsPanel>
            <StatisticsList>
              {getStatisticsItems(playlist).map((item, index) => (
                <StatisticsPanelItem key={index}>
                  <StatisticsPanelItemText>{item.text}</StatisticsPanelItemText>
                  {item.icon}
                </StatisticsPanelItem>
              ))}
            </StatisticsList>
          </StatisticsPanel>
          <VideoListWrapper>
            {isSearchLoading && <FullScreenLoader />}
            {searchVideos &&
              searchQueryValue &&
              (searchVideos.length ? (
                // <VideoList videos={searchVideos} videoSettings={{ withTime: true }} />
                <CardList>
                  {searchVideos.map((fragment) =>
                    fragment.cues.map((cue, i) => (
                      <Link to={`watch/${fragment.publicId}/?t=${cue.timestampLink}`} key={fragment.publicId + i}>
                        <VideoFragmentCard fragment={cue} videoPreview={fragment.thumbnailUrl} />
                      </Link>
                    )),
                  )}
                </CardList>
              ) : (
                <Title>{t('main.no_result', { ns: 'pages' })}</Title>
              ))}
            {!searchQueryValue && <VideoList videos={playlist.videos} />}
          </VideoListWrapper>
        </>
      )}
    </>
  );
};

export default memo(PlaylistPrivatePage);

PlaylistPrivatePage.displayName = 'PlaylistPrivatePage';
