import { EditTimecodesWrapper, TitleTimeWrapper } from "./TitleTime.styled"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import MyInput from "@/components/Shared/MyInput/MyInput"
import { getMinutes } from "@/pages/VideoPage/components/helpers"
import { Timecode } from "@/types"

type TimecodesProps = {
  start: number;
  index: number
}


export const TitleTime = ({ index, start }: TimecodesProps) => {
  // const { title, text, start } = timecode;
  const actualTime = getMinutes(start)
  const { t } = useTranslation('pages');




  const { register } = useFormContext<{ timecodes: Timecode[] }>();
  // const handleChangeTitle = (e: { target: { value: string } }) => {
  //   onChange({ ...timecode, title: e.target.value });
  // }

  // const handleChangeMainText = (e: { target: { value: string } }) => {
  //   onChange({ ...timecode, text: e.target.value });
  // }

  // const handleChangeTimecode = (e: { target: { value: string } }) => {
  //   onChange({ ...timecode, start: +e.target.value });
  // }

  return (
    <EditTimecodesWrapper>
      <TitleTimeWrapper>
        <MyInput {...register(`timecodes.${index}.title`)} placeholder={t('admin.timecodes_title')} />
        <div>
          <MyInput {...register(`timecodes.${index}.start`)} value={actualTime} placeholder="00:00" style={{ maxWidth: '100px', padding: '12px 16px', overflow: 'hidden' }} />
        </div>
      </TitleTimeWrapper>
      <MyInput {...register(`timecodes.${index}.text`)} placeholder={t('admin.timecodes_text')} />
    </EditTimecodesWrapper>
  )
}
