import styled from 'styled-components';

export const QuizAnswersWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 10px;
justify-content: center;
`;

export const QuizBlockWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
background: #0B002C;
`;