import styled from 'styled-components';

export const EditTimecodesWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 16px;
justify-content: center;
align-items: flex-start;
`;

export const TitleTimeWrapper = styled.div`
width: 100%;
display: flex;
gap: 20px;
justify-content: center;
align-items: flex-start;
`;